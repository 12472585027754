import React, { useState, useEffect } from 'react';

import MeetupList from '../components/meetups/MeetupList';



function AllMeetupsPage() {
    const [getLoadingState, setLoadingState] = useState(true);
    const [getLoadedMeetups, setLoadedMeetups] = useState([]);
    useEffect( () => {
      setLoadingState(true);
      fetch('https://react-getting-started-1e5ac-default-rtdb.firebaseio.com/meetups.json'
      ).then( response => {
        return response.json();
      }).then(data => {
        const meetups = [];

        for(const key in data){
          const meetup = {
            id: key,
            ...data[key]
          };

          meetups.push(meetup);

        }

        setLoadingState(false);
        setLoadedMeetups(meetups);
      });
    } , []);
      

    if(getLoadingState){
      return (
        <section>
          <p>Loading...</p>
        </section>
      );
    }
    
    return <div> 
                <section>
                    <h1>All Meetings</h1>
                    <ul>
                      <MeetupList meetups={getLoadedMeetups}></MeetupList>
                    </ul>
                </section>
         </div>;
}

export default AllMeetupsPage;