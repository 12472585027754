import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NewMeetupPage from './pages/NewMeetup';
import AllMeetupsPage from './pages/AllMeetups';
import FavoritesPage from './pages/Favorites';
import MainNavigation from './components/layout/MainNavigation';
import Layout from './components/layout/Layout';


function App() {
  return (
  
  <Layout>
    
    <Switch>
    <Route path='/' exact >
      <AllMeetupsPage />
    </Route>
    <Route path="/newmeetup">
      <NewMeetupPage />
    </Route>
    <Route path="/favorites">
      <FavoritesPage />
    </Route>
    </Switch>
  </Layout>
  
  );
}

export default App;
